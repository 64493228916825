export default defineNuxtRouteMiddleware(async (to) => {
  const app = useNuxtApp()

  // we need to reload the page here, otherwise the direcus sdk fails the first login request
  const redirectToLogin = () => {
    if (to && to.path) {
      window.location = '/auth/login?path=' + encodeURIComponent(to.fullPath)
      return
    }
    window.location = '/auth/login'
    return
  }

  // Check If the user is authenticated
  const token = await app.$cms.getToken()
  if (!token) {
    const staticToken = localStorage.getItem('auth_token')
    if (staticToken) {
      app.$cms.setToken(staticToken)
    } else {
      try {
        await app.$cms.refresh()
      } catch (error) {
        console.log(error)
        redirectToLogin()
      }
    }
  }

  // retrieve current user if state is empty
  if (!app.$store.user.id) {
    try {
      const user = await app.$cms.request(
        app.$readMe({
          fields: [
            'id',
            'email',
            'first_name',
            'last_name',
            'phone_number',
            'role.id',
            'role.facility_types',
            'role.identifier',
            'main_facility.id',
            'main_facility.name',
            'main_facility.premium_features',
            'main_facility_admin',
            'facilities.id',
            'facilities.type',
            'facilities.facility_id.id',
            'facilities.facility_id.name',
            'physician',
            'configurations.id',
            'configurations.key',
            'configurations.value',
            'configurations.additional_value',
            'tfa_secret',
            'account_status',
            'position',
          ],
        })
      )
      app.$store.user = user
      app.$sentry.setUser({ id: user.id, email: user.email })
      if (app.$store.user.role.identifier === 'ADMIN') {
        app.$notificationStore.set({
          text: 'Sie sind als Admin angemeldet. Bitte verwenden Sie das Admin-Panel.',
          type: 'warning',
        })
        return navigateTo('/auth/login')
      }
      if (!app.$store.user.first_name || !app.$store.user.last_name) {
        return navigateTo('/onboarding/forward')
      }
      if (app.$store.user.account_status === 'setEmail' && to.path !== '/auth/termination') {
        return navigateTo('/auth/termination')
      }

      // Establish a connection for realtime
      await app.$cms.connect()
    } catch (error) {
      return navigateTo('/auth/logout')
    }
  }
})
